<template>
  <div class="contact__container" ref="wrapper">
    <div class="contact__up-part scrollObj" ref="manualShow">
      <div class="up__body-container">
        <div class="up__inner">
          <div class="location__info scrollObj">
            <div class="info__item">
              <p>上海市 徐汇区 肇嘉浜路736号 龙头大厦B1、5-6楼</p>
              <p v-if="$bowser.mobile">
                Floor B1, 5-6, Longtou building, No. 736, Zhaojiabang<br />Road,Xuhui
                District,Shanghai
              </p>
              <p v-else>
                Floor B1, 5-6, Longtou building, No. 736, Zhaojiabang Road,<br />Xuhui
                District,Shanghai
              </p>
            </div>
            <div class="info__item">
              <p>四川省 成都市 高新区蜀锦路88号 新中泰国际大厦2702室</p>
              <p>
                Room 2702, xinzhongtai international building, No. 88, Shujin Road,<br />Gaoxin
                District, Chengdu, Sichuan
              </p>
            </div>
          </div>
          <div class="contacts__info up__info-row scrollObj">
            <p class="info__title">商务联系 Marketing Dept.</p>
            <div class="info__item">
              <p>联系人_闫经理</p>
              <p>Tel_021-54657752</p>
              <p>Mob_18930913720</p>
            </div>
            <div class="info__item">
              <p>Web_<a href="www.facedesign.cn">www.facedesign.cn</a></p>
              <p>E-mail_<a mailto="face_design@126.com">face_design@126.com</a></p>
            </div>
          </div>
          <div class="wechat__info up__info-row scrollObj">
            <p class="info__title">WeChat</p>
            <div class="info__item">
              <img src="@/assets/images/qr.jpg" alt="" />
              <img src="@/assets/images/qr1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page__title scrollObj" ref="title">contact us</div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import events from '@/utils/eventBus';

const bgmap = [
  require('@/assets/images/demo1/contact-bg-1.jpg'),
  require('@/assets/images/demo1/contact-bg-2.jpg'),
  require('@/assets/images/demo1/contact-bg-3.jpg'),
];

const bgmapmobile = [
  require('@/assets/images/mobile/contact-bg-1.jpg'),
  require('@/assets/images/mobile/contact-bg-2.jpg'),
  require('@/assets/images/mobile/contact-bg-3.jpg'),
];

export default {
  name: 'view-contact',
  mounted() {
    this.heightFullScreen(this.$refs.wrapper);
    this.$nextTick(() => {
      const bg = this.$bowser.mobile ? bgmapmobile : bgmap;
      this.$refs.wrapper.style.backgroundImage = `url(${bg[parseInt(Math.random() * 3, 10)]})`;
      setTimeout(() => {
        this.$refs.manualShow.classList.add('animated');
      }, 300);
      if (this.$bowser.mobile) {
        setTimeout(() => {
          this.$refs.title.classList.add('animated');
        }, 400);
      }
      events.$emit('pageMounted');
    });
  },
};
</script>

<style></style>
